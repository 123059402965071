<template>
<section class="serviceTable">
    <pageLoader v-if="showloader" :loadingText="'Traitement des données en cours'"/>
    <div class="page-header">
      <h3 class="page-title">
        Service
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Service</a></li>
          <li class="breadcrumb-item active" aria-current="page">Archives</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="card-title">Liste de tous les services supprimés</h4>
                <p  class="text-muted pointer" @click="services()">Services actifs</p>
              </div>
            <div class="row">
              <div class="col-md-2">
              </div>
              <div class="col-md-4 offset-md-6">
                <b-input-group>
                      <b-form-input placeholder="Recherche" @keyup="findService()" v-model="search"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
            </div>
            <span v-else>
              <!-- new interface: card version -->
              <div class="row mt-4">
                <div class="col-md-12 d-flex justify-content-between">
                  <span class="d-flex">
                    <p>Filtrer</p>
                    <select class="custom-select custom-select-sm mx-2" style="max-width:11rem" v-model="selectedCategory" aria-label="Default select example">
                      <option value="all" selected>Toutes les catégories</option>
                      <option v-for="(category,categoryId) in categories" :key="categoryId" :value="category.ulid">{{category.category}}</option>
                    </select>
                  </span>
                  <b-pagination class="rounded-separated d-flex justify-content-center computer" v-if="servicedisplay.length > 20" v-model="currentPage" :per-page="perPage" :total-rows="servicedisplay.length"></b-pagination>
                </div>

                <!-- liste des services -->
                <div class="col-md-12" v-for="(item, index) in paginatedCards" :key="index">
                  <!-- 01FY46PE2Y9N67CFS9ZEFW05DH est l'ulid du service fermeture qui ne doit pas être affiché -->
                  <div class="card" v-if="item.ulid != '01FY46PE2Y9N67CFS9ZEFW05DH'">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <span>
                          <h4 class="pointer" @click="editService(item)">{{item.name}}</h4>
                          <p class="text-muted" v-for="(itemtags, itemindex) in item.tags" :key="itemindex">{{itemtags}}</p>
                        </span>
                        <span class="d-flex">
                          <button class="btn btn-social-icon btn-google" @click="showAlert(item)"><i class="fa fa-trash"></i></button>
                          <button class="btn btn-sm btn-social-icon btn-facebook" title="Annuler la suppression" @click="recover(item)"><i class="fa fa-recycle"></i></button>
                        </span>
                      </div>
                      <div>
                        <p>{{item.description}}</p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>
                          <i class="fa fa-database text-muted"></i> {{selectedCategory=="all"?item.category.category:selectedCategoryName}}
                        </span>
                        <span>
                          <i class="fa fa-clock-o text-muted"></i> {{calculTimes(item.duration)}}
                        </span>
                        <span>
                          <i class="fa fa-money text-muted"></i> {{item.price}} €
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 text-right mt-5">
                  <b-pagination class="rounded-separated d-flex justify-content-end text-right computer" v-if="servicedisplay.length > 6" v-model="currentPage" :per-page="perPage" :total-rows="servicedisplay.length"></b-pagination>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require ('axios').default
import pageLoader from '../../components/pageLoader.vue'

export default {
  name: 'service-archive',
  components: {
    pageLoader
  },
  data () {
    return  {
      search:'',
      servicedisplay:[],
      showloader: false,
      isload:true,
      fields: [
        // { key: 'ulid', label:'ulid', sortable: true},
        { key: 'name', label:'Nom de du service', sortable: true},
        { key: 'description', label:'description', sortable: true},
        { key: 'price', label:'Prix', sortable: true},
        { key: 'tags', label:'tags', sortable: true},
        { key: 'duration', label:'duration', sortable: true},
        { key: 'action', label:''}
      ],
      service:[],
      categories:[],
      selectedCategory:"all",
      selectedCategoryName:"",
      // pagination
      currentPage: 1,
      perPage: 20
    }
  },
  computed: {
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      // console.log("service display", this.servicedisplay);
      return this.servicedisplay.slice(start, end);
      }
  },
  watch: {
    todos: {
      handler () {
        localStorage.setItem('todos', JSON.stringify(this.todos))
      },
      deep: true
    },
    selectedCategory(value){
      console.log({value})
      this.selectedCategory=="all" ? this.getService(): this.getServicePerCategory(value)
    }
  },
  methods: {
    async getService() {
     await axios.get('services')
     .then(resService=> {
       this.service= resService.data.result.filter( ser => !ser.isactive );
       this.isload = false
       //console.log(this.service);
       this.servicedisplay = this.service
       })
     .catch(errService=> console.log(errService))
    },
    // get par catégories si l'utilisateur filtre 
    async getServicePerCategory(ulid){
      await axios.get(`categories/${ulid}`)
      .then(resGetServicePerCategory => {
        console.log("category",resGetServicePerCategory);
        this.service = resGetServicePerCategory.data.services
        this.selectedCategoryName = resGetServicePerCategory.data.category
        this.isload = false
        this.servicedisplay = this.service
      })
      .catch(errGetServicePerCategory => console.log(errGetServicePerCategory))
    },
    findService()
        {   
            this.currentPage=1
            this.servicedisplay=null
            const regex = new RegExp(this.search.toLowerCase());
            let newservice = []
            this.service.forEach(element => {
                if(regex.test(element.name.toLowerCase())){
                    newservice.push(element)
                }
            })
            this.servicedisplay = newservice
        },  
      calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
        recover(item){
            axios.put(`services/${item.ulid}`,{
                isactive:true
            },{
                headers:{
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(() => alert('Le service a été remis sur le serveur'))
            .catch(errRecover=>console.log(errRecover))
        },
        // Suppression totale
        deleteService(service) {
        this.showloader = true
        axios.delete(`services/${service.ulid}`, {
            headers: {
            'X-AUTH-TOKEN': localStorage.getItem('token')
            }
        })
        .then(()=> {
        this.showloader = false
        this.getService()
            this.service.splice(this.service.indexOf(service), 1);
        })
        .catch(err => console.log(err))
        return true
        },
    showAlert (service) {
        this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous supprimer définitivement ce service?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Supprimer',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
       this.isload = true
        if (result.value && this.deleteService(service)) {
          this.$swal(
            'Supprimé!',
            'Le service a bien été supprimé définitivement',
            'success'
          )
       this.isload = false
        
        }
      })
    },
    getCategories(){
      axios.get('categories')
      .then(resGetCategories => {
        this.categories=resGetCategories.data.result
      })
      .catch(errGetCategories => console.log(errGetCategories))
    },
    services(){
        this.$router.push({
            name:"service"
        })
    }
  },
  async mounted () {
    // global categories
    this.getService()
    this.getCategories()
  }
}
</script>
<style scoped>
.text-limit {
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1;
-webkit-box-orient: vertical;

}
</style>